//general
.p-general {
  h2 {
    @extend .c-title-bdr; 
  }

  h3 {
    @extend .c-subtitle;
  }

  p {

    &+*,
    *+& {
      margin-top: 20px;
    }
  }

  p {
    line-height: 1.75;

    @media only screen and (min-width: $mq-pc) {
      font-size: 18px;
    }

    @media only screen and (max-width: $mq-sp) {
      font-size: 16px;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }
}

@media only screen and (min-width: $mq-pc) {}

@media only screen and (max-width: $mq-sp) {}