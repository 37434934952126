//title
.c-title-header {
  background: $color-blue;
  color: #fff;

  @media only screen and (min-width: $mq-pc) {
    margin-bottom: 60px;
  }

  @media only screen and (max-width: $mq-sp) {
    margin-bottom: 20px;
  }

  &__title {
    display: block;
    text-align: center;
    padding: .917em 0;
    line-height: 1.5;
  }

  &__kv {
    background-color: #fff;
    background-position: center;
    background-size: cover;

    @media only screen and (min-width: $mq-pc) {
      width: 100%;
      height: 360px;
    }

    @media only screen and (max-width: $mq-sp) {
      width: 100%;
      height: 260px;
    }
  }
}

.c-title-bar {
  background: $color-blue;
  color: #fff;

  &__title {
    display: block;
    text-align: center;
    padding: .625em 0;
    line-height: 1.5;
  }
}

.c-title-bdr {
  color: $color-blue;
  font-weight: $normal;
  border-left: 3px solid $color-blue;
  padding-left: 10px;
  line-height: 1.2;
  margin-bottom: 20px;

  @media only screen and (min-width: $mq-pc) {
    font-size: 28px;
  }

  @media only screen and (max-width: $mq-sp) {
    font-size: 26px;
  }
}

.c-subtitle {
  font-weight: $bold;
  line-height: (32 / 18);
  margin-bottom: 6px;

  @media only screen and (min-width: $mq-pc) {
    font-size: 18px;
  }

  @media only screen and (max-width: $mq-sp) {
    font-size: 16px;
  }
}

//label
.c-laebl--q {
  background: $color-blue;
  color: #fff;
  border-radius: 3em;
  display: inline-block;
  width: 1.25em;
  height: 1.25em;
  line-height: 1.35;
  text-align: center;
  margin-right: 10px;
}

//section {
.c-section {
  @media only screen and (min-width: $mq-pc) {
    margin-top: 60px;
  }

  @media only screen and (max-width: $mq-sp) {
    margin-top: 60px;
  }
}

//clm
.c-flexclm {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: -20px;

  &__li {
    margin-top: 20px;
    width: 100%;
  }
}

.c-flexclm-auto {
  display: flex;
  flex-wrap: wrap;

  &__li {
    margin-right: 10px;
  }
}

.c-flexclm-2-1 {
  @extend .c-flexclm;

  @media only screen and (min-width: $mq-pc) {
    margin-top: -30px;
  }

  @media only screen and (max-width: $mq-sp) {
    margin-top: -30px;
  }

  &__li {

    @media only screen and (min-width: $mq-pc) {
      margin-top: 30px;
      width: calc(50% - 20px);
    }

    @media only screen and (max-width: $mq-sp) {
      margin-top: 30px;
      width: 100%;
    }
  }
}

.c-flexclm-2-2 {
  @extend .c-flexclm;

  @media only screen and (min-width: $mq-pc) {
    margin-top: -30px;
  }

  @media only screen and (max-width: $mq-sp) {
    margin-top: -30px;
  }

  &__li {

    @media only screen and (min-width: $mq-pc) {
      margin-top: 30px;
      width: calc(50% - 10px);
    }

    @media only screen and (max-width: $mq-sp) {
      margin-top: 30px;
      width: calc(50% - 10px);
    }
  }
}

.c-flexclm-3-1 {
  @extend .c-flexclm;
  margin-top: -25px;

  &__li {
    margin-top: 25px;

    @media only screen and (min-width: $mq-pc) {
      width: calc(33.3333% - (25px * 2 / 3));
    }

    @media only screen and (max-width: $mq-sp) {
      width: 100%;
    }
  }
}

.c-flexclm-3-2 {
  @extend .c-flexclm;
  margin-top: -8px;

  &__li {
    margin-top: 8px;

    @media only screen and (min-width: $mq-pc) {
      width: calc(33.3333% - (8px * 2 / 3));
    }

    @media only screen and (max-width: $mq-sp) {
      width: calc(50% - 4px);
    }
  }
}

.c-flexclm-4-2 {
  @extend .c-flexclm;

  @media only screen and (min-width: $mq-pc) {
    margin-top: -8px;
  }

  @media only screen and (max-width: $mq-sp) {
    margin-top: -15px;
  }

  &__li {
    @media only screen and (min-width: $mq-pc) {
      margin-top: 8px;
      width: calc(25% - (8px * 3 / 4));
    }

    @media only screen and (max-width: $mq-sp) {
      margin-top: 15px;
      width: calc(50% - 4px);
    }
  }
}

.c-flexclm-4-4 {
  @extend .c-flexclm;
  margin-top: -8px;

  &__li {
    margin-top: 8px;
    width: calc(25% - (8px * 3 / 4));
  }
}

//table
.c-table {
  width: 100%;

  th,
  td {
    padding: 10px;
    line-height: 1.5;
  }

  thead {
    th {
      background: $color-blue;
      color: #fff;
      font-weight: $bold;
    }
  }

  tbody {
    tr:nth-child(even) {
      background: rgba(10, 45, 136, .1);
    }
  }
}

//hr
hr {
  border-top: 1px solid rgba(10, 45, 136, .2);


  @media only screen and (min-width: $mq-pc) {
    margin: 15px auto 30px;
  }

  @media only screen and (max-width: $mq-sp) {
    margin: 12px auto 20px;
  }
}

//btn
.c-button-img {
  cursor: pointer;
  display: inline-block;
  transition: opacity .3s ease;

  &:hover {
    opacity: .8;
    transition: opacity .3s ease;
  }
}

.c-button-wrap {
  display: flex;
  height: 100%;
}

.c-button-single {
  display: flex;
  justify-content: center;

  .c-button {
    @media only screen and (min-width: $mq-pc) {
      max-width: 360px;
    }

    @media only screen and (max-width: $mq-sp) {}
  }
}

.c-button {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  font-weight: $bold;
  width: 100%;
  height: 100%;
  color: #fff;
  text-decoration: none;
  padding: .875em calc(1em + 38px) .875em 18px;
  background: $color-blue;
  border: 2px solid $color-blue;
  transition: background .3s ease, border .3s ease, color .3s ease;

  @media only screen and (max-width: $mq-sp) {
    padding: .875em calc(1em + 34px) .875em 14px;
  }

  &:hover {
    color: $color-blue;
    background: #fff;
    transition: background .3s ease, border .3s ease, color .3s ease;
  }

  &:before {
    font-family: '#{$icomoon-font-family}';
    color: inherit;
    content: $icon-arrow;
    display: block;
    width: 1em;
    height: 1em;
    line-height: 1;
    position: absolute;
    z-index: 1;
    right: 20px;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}

.c-button--gray {
  @extend .c-button;
  background: #B4B4B4;
  border: 2px solid #B4B4B4;

  &:hover {
    color: #B4B4B4;
    background: #fff;
    transition: background .3s ease, border .3s ease, color .3s ease;
  }
}

.c-button--white {
  @extend .c-button;
  background: #fff;
  color: $color-blue;
  border: 2px solid #fff;

  &:hover {
    color: #fff;
    background: $color-blue;
    transition: background .3s ease, border .3s ease, color .3s ease;
  }
}

.c-button--ib {
  width: auto;
}

.c-button--invert {
  @extend .c-button;
  background: #fff;
  color: $color-blue;

  &:hover,
  &.is-current {
    color: #fff;
    background: $color-blue;
  }
}

.c-button--anchor {
  &:before {
    transform: rotate(90deg);
    right: 15px;
  }
}

.c-button-alt {
  cursor: pointer;
  position: relative;
  display: inline-block;
  font-weight: $bold;
  color: #fff;
  text-decoration: none;
  padding: .5em calc(1em + 30px) .5em 10px;
  color: $color-blue;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  border-bottom-color: $color-blue;
  transition: right .3s ease;

  &:hover {
    &:before {
      right: 2px;
      transition: right .3s ease;
    }
  }

  &:before {
    transition: right .3s ease;
    font-family: '#{$icomoon-font-family}';
    color: inherit;
    content: $icon-arrow;
    display: block;
    width: 1em;
    height: 1em;
    line-height: 1;
    position: absolute;
    z-index: 1;
    right: 10px;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}

.c-button-alt--up {
  &:before {
    transition: top .3s ease;
    transform: rotate(-90deg);
  }
  &:hover {
    &:before {
      right: 10px;
      top: -10px;
      transition: top .3s ease;
    }
  }
}

.c-button-icon {
  cursor: pointer;
  font-size: 20px;
  position: relative;
  display: flex;
  font-weight: $bold;
  width: 100%;
  height: 100%;
  color: #fff;
  text-decoration: none;
  background: $color-blue;
  border: 2px solid $color-blue;
  transition: background .3s ease, border .3s ease, color .3s ease;

  @media only screen and (min-width: $mq-pc) {
    padding: 20px calc(1em + 20px) 20px 20px;
  }

  @media only screen and (max-width: $mq-sp) {
    padding: 15px calc(1em + 15px) 15px 15px;
  }

  &:hover {
    opacity: .8;
    transition: opacity .3s ease, border .3s ease, color .3s ease;
  }

  &:before {
    font-family: '#{$icomoon-font-family}';
    color: inherit;
    content: $icon-arrow;
    display: block;
    width: 1em;
    height: 1em;
    line-height: 1;
    position: absolute;
    z-index: 1;
    right: 15px;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  &__icon {
    border-radius: 6px;
    background: #fff;

    @media only screen and (min-width: $mq-pc) {
      width: 56px;
      height: 56px;
    }

    @media only screen and (max-width: $mq-sp) {
      width: 50px;
      height: 50px;
    }
  }

  &__cont {
    flex: 1 0 0%;
    display: flex;
    flex-direction: column;

    padding-top: 5px;

    @media only screen and (min-width: $mq-pc) {
      margin-left: 20px;
    }

    @media only screen and (max-width: $mq-sp) {
      margin-left: 15px;
    }
  }

  &__text {
    font-size: 16px;
    font-weight: $bold;
    word-break: break-all;
  }

  &__link {
    margin-top: 0.5em;
    font-size: 14px;
    text-decoration: underline;
    font-weight: $normal;
    word-break: break-all;
  }
}

//box
.c-borderbox {
  border: 2px solid $color-blue;
  background: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__title {
    background: $color-blue;
    color: #fff;
    font-weight: $bold;
    text-align: center;
    line-height: 1.5;
    margin: 0;

    @media only screen and (min-width: $mq-pc) {
      padding: .625em 28px;
    }

    @media only screen and (max-width: $mq-sp) {
      padding: .625em 18px;
    }
  }

  &__cont {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1 0 auto;

    @media only screen and (min-width: $mq-pc) {
      padding: 28px;
    }

    @media only screen and (max-width: $mq-sp) {
      padding: 18px;
    }
  }
}

.c-bgcolorbox--lightblue {
  background: rgba(10, 45, 136, .1);
  padding: 10px;
}

//ページ遷移
.c-prevnext {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color-blue;
  font-weight: $bold;

  @media only screen and (min-width: $mq-pc) {
    font-size: 14px;
    margin: 40px auto;
  }

  @media only screen and (max-width: $mq-sp) {
    font-size: 14px;
    margin: 20px auto;
  }

  &__li {
    margin: 0 10px;
  }

  a {
    text-decoration: none;
    display: block;
    border-bottom: 1px solid $color-blue;
    line-height: 1;
    padding: 0 0 5px;

    &:hover {
      border-bottom: 1px solid transparent;
      text-decoration: none;
    }
  }
}

//ul li
.c-ul--none {
  list-style: none;
}

@media only screen and (min-width: $mq-pc) {}

@media only screen and (max-width: $mq-sp) {}