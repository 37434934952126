@font-face {
  font-family: '#{$icomoon-font-family}';
  src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?515b58');
  src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?515b58#iefix') format('embedded-opentype'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?515b58') format('truetype'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?515b58') format('woff'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?515b58##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="c-icon-"],
[class*=" c-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  //speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &:before,
  &:after {
    display: block;
    width: 1em;
    height: 1em;
  }
}

.c-icon-arrow {
  &:before {
    content: $icon-arrow;
  }
}

.c-icon-arrow-invert {
  &:before {
    content: $icon-arrow;
    transform: rotate(180deg);
  }
}

.c-icon-arrow2 {
  &:before {
    content: $icon-arrow2;
  }
}