//anchor {
.u-anchor {
  display: block;
  margin-top: -80px;
  padding-top: 80px;
}

//textsize {
.u-text {
  &--xxxxl {
    line-height: (32 / 18);

    @media only screen and (min-width: $mq-pc) {
      font-size: 36px;
    }

    @media only screen and (max-width: $mq-sp) {
      font-size: 24px;
    }
  }

  &--xxxl {
    line-height: (32 / 18);

    @media only screen and (min-width: $mq-pc) {
      font-size: 30px;
    }

    @media only screen and (max-width: $mq-sp) {
      font-size: 28px;
    }
  }

  &--xxl {
    line-height: (32 / 18);

    @media only screen and (min-width: $mq-pc) {
      font-size: 28px;
    }

    @media only screen and (max-width: $mq-sp) {
      font-size: 26px;
    }
  }

  &--xl {
    line-height: (32 / 18);

    @media only screen and (min-width: $mq-pc) {
      font-size: 18px;
    }

    @media only screen and (max-width: $mq-sp) {
      font-size: 16px;
    }
  }

  &--lg {
    line-height: (32 / 18);

    @media only screen and (min-width: $mq-pc) {
      font-size: 16px;
    }

    @media only screen and (max-width: $mq-sp) {
      font-size: 14px;
    }
  }

  &--md {
    line-height: (32 / 18);

    @media only screen and (min-width: $mq-pc) {
      font-size: 14px;
    }

    @media only screen and (max-width: $mq-sp) {
      font-size: 12px;
    }
  }

  &--sm {
    line-height: (32 / 18);

    @media only screen and (min-width: $mq-pc) {
      font-size: 12px;
    }

    @media only screen and (max-width: $mq-sp) {
      font-size: 10px;
    }
  }

  &--xs {
    line-height: (32 / 18);

    @media only screen and (min-width: $mq-pc) {
      font-size: 10px;
    }

    @media only screen and (max-width: $mq-sp) {
      font-size: 9px;
    }
  }
}

.u-bold {
  font-weight: $bold;
}

.u-normal {
  font-weight: $normal;
}

.u-color--blue {
  color: $color-blue;
}

.u-color--red {
  color: #E2421F;
}

//display
.u-display {
  &--ib {
    display: inline-block;
  }
}

//align {
.u-alignC {
  text-align: center;
  justify-content: center;
}

.u-alignL {
  text-align: left;
  justify-content: flex-start;
}

.u-alignC-L {
  @media only screen and (min-width: 768px) {
    text-align: center;
  }

  @media only screen and (max-width: 767px) {
    text-align: left;
  }
}

//indent
.u-indent {
  padding-left: 1em;
  text-indent: -1em;
}

//resize {
.u-resize {
  max-width: 100%;
  width: 100%;
  height: auto;
}

.u-resize-max {
  max-width: 100%;
  height: auto;
}

//margin
.u-margin {
  &-t {
    &--double {
      @media only screen and (min-width: $mq-pc) {
        margin-top: 120px;
      }

      @media only screen and (max-width: $mq-sp) {
        margin-top: 60px;
      }
    }

    &--section {
      @media only screen and (min-width: $mq-pc) {
        margin-top: 60px;
      }

      @media only screen and (max-width: $mq-sp) {
        margin-top: 30px;
      }
    }

    &--1 {
      margin-top: 10px;
    }

    &--2 {
      margin-top: 20px;
    }

    &--3 {
      margin-top: 30px;
    }

    &--1em {
      margin-top: 1em;
    }

    &--2em {
      margin-top: 2em;
    }

    &--3em {
      margin-top: 3em;
    }
  }

  &-b {
    &--double {
      @media only screen and (min-width: $mq-pc) {
        margin-bottom: 120px;
      }

      @media only screen and (max-width: $mq-sp) {
        margin-bottom: 60px;
      }
    }

    &--section {
      @media only screen and (min-width: $mq-pc) {
        margin-bottom: 60px;
      }

      @media only screen and (max-width: $mq-sp) {
        margin-bottom: 30px;
      }
    }

    &--1 {
      margin-bottom: 10px;
    }

    &--2 {
      margin-bottom: 20px;
    }

    &--3 {
      margin-bottom: 30px;
    }

    &--1em {
      margin-bottom: 1em;
    }

    &--2em {
      margin-bottom: 2em;
    }

    &--3em {
      margin-bottom: 3em;
    }
  }
}

.u-padding {
  &-t {
    &--1 {
      padding-top: 10px;
    }

    &--2 {
      padding-top: 20px;
    }

    &--3 {
      padding-top: 30px;
    }

    &--1em {
      padding-top: 1em;
    }

    &--2em {
      padding-top: 2em;
    }

    &--3em {
      padding-top: 3em;
    }
  }

  &-b {
    &--1 {
      padding-bottom: 10px;
    }

    &--2 {
      padding-bottom: 20px;
    }

    &--3 {
      padding-bottom: 30px;
    }

    &--1em {
      padding-bottom: 1em;
    }

    &--2em {
      padding-bottom: 2em;
    }

    &--3em {
      padding-bottom: 3em;
    }
  }
}

//hidden
.u-hidden-sp {
  @media only screen and (max-width: $mq-sp) {
    display: none;
  }
}

.u-hidden-pc {
  @media only screen and (min-width: $mq-pc) {
    display: none;
  }
}

@media only screen and (min-width: $mq-pc) {}

@media only screen and (max-width: $mq-sp) {}