.scroll-prevent {
  position: fixed;
  z-index: -1;
  width: 100%;
  height: 100%;
}

.l-wrapper {
  background: url(/assets/common/img/bg_content.png) repeat;
  background-position: 0 0;
  background-size: auto;

  @media only screen and (min-width: $mq-pc) {
    padding-top: 60px;
  }

  @media only screen and (max-width: $mq-sp) {
    padding-top: 60px;
  }
}

.l-inner {
  width: 100%;
  max-width: 1024px;
  padding: 0 20px;
  margin: 0 auto;
  position: relative;
}

.l-inner800 {
  max-width: 840px;
  padding: 0 20px;
  margin: 0 auto;
  position: relative;
}

.l-content {
  overflow: hidden;
  @media only screen and (min-width: $mq-pc) {
    padding-bottom: 160px;
  }

  @media only screen and (max-width: $mq-sp) {
    padding-bottom: 110px;
  }
}

@media only screen and (min-width: $mq-pc) {}

@media only screen and (max-width: $mq-sp) {}