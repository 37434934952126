.l-header {
  position: fixed;
  z-index: 9;
  left: 0;
  width: 100%;
  top: 0;
}

.p-header {

  @media only screen and (min-width: $mq-pc) {
    background: linear-gradient(90deg, #fff 65.9%, $color-blue 66%);
  }

  @media only screen and (max-width: $mq-sp) {
    overflow: hidden;
  }

  &__inner {
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 2;
    height: 60px;

    @media only screen and (min-width: $mq-pc) {
      max-width: 1024px;
      width: 1024px;
      padding: 0 20px;
    }

    @media only screen and (max-width: $mq-sp) {
      background: #fff;
      padding: 0 0;
    }
  }

  &__logo {
    @media only screen and (min-width: $mq-pc) {
      position: absolute;
      top: 0;
      z-index: 2;
      left: 20px;
    }

    a {
      display: block;

      @media only screen and (max-width: $mq-sp) {
        width: 54px;
        height: 60px;

        img {
          width: 54px;
          height: 60px;
        }
      }
    }
  }

  &__navl {
    @media only screen and (min-width: $mq-pc) {
      background: #fff;
      display: flex;
      flex: 1 0 0%;
      justify-content: flex-start;
      align-items: center;
      padding: 0 20px 0 104px;
    }

    &__li {
      @media only screen and (min-width: $mq-pc) {
        margin: 0 10px;
      }
    }
  }

  &__navr {
    @media only screen and (min-width: $mq-pc) {
      font-size: 14px;
      background: $color-blue;
      color: #fff;
      display: flex;
      width: 326px;
      align-items: center;
      padding-left: 26px;
      white-space: nowrap;
    }
  }

  &__recruit {
    @media only screen and (min-width: $mq-pc) {
      font-weight: $bold;
      border-right: 1px solid #fff;
      margin-right: 19px;
      padding-right: 19px;

      a {
        color: #fff;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &__lang {
    display: flex;
    align-items: center;
    font-weight: $bold;

    &btnwrap {
      display: flex;
      align-items: center;
      padding-left: 3px;
    }

    &btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 36px;
      border-radius: 3.6em;
      font-size: 10px;
      border: 1px solid #fff;
      margin-left: 6px;
      color: #fff;
      text-decoration: none;

      transition: color .3s ease, background .3s ease;

      &:hover,
      &.is-current {
        background: #fff;
        color: $color-blue;
        text-decoration: none;
        transition: color .3s ease, background .3s ease;
      }
    }
  }

  &__navsp {
    @media only screen and (max-width: $mq-sp) {
      display: flex;
    }

    &__li {
      @media only screen and (max-width: $mq-sp) {
        font-size: 10px;
        color: #fff;
        display: flex;
        align-items: center;
        border-left: 1px solid $color-blue;
      }
    }

    &__btn {
      @media only screen and (max-width: $mq-sp) {
        cursor: pointer;
        text-decoration: none;
        width: 60px;
        height: 60px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }

  &-navspbtn {
    background: $color-blue;
    width: 34px;
    height: 34px;
    border-radius: 34px;
    position: relative;

    >span {
      display: block;
      height: 2px;
      width: 16px;
      background-color: #ffffff;
      position: absolute;
      z-index: 1;
      margin: auto;
      left: 0;
      right: 0;

      &:nth-child(1) {
        top: 12px;
        transition: .3s ease;

        .is-menuopen & {
          top: 16px;
          bottom: 16px;
          transform: rotate(45deg);
          transition: .3s ease;
        }
      }

      &:nth-child(2) {
        bottom: 12px;
        transition: .3s ease;

        .is-menuopen & {
          bottom: 16px;
          top: 16px;
          transform: rotate(-45deg);
          transition: .3s ease;
        }
      }
    }
  }
}

.p-header-spmenu {
  background: $color-blue;
  position: relative;
  z-index: 1;
  height: 0;
  transform: translateY(-100%);
  transition: .3s ease;
  overflow: hidden;
  overflow-y: scroll;

  .is-menuopen & {
    max-height: calc(100vh - 60px);
    height: 100vh;
    transform: translateY(0%);
    transition: .3s ease;
  }

  &__scr {
    height: 100%;
  }

  &__bnr {
    padding: 15px 20px 20px;
    background: $color-blue;
    text-align: center;

    img {
      max-width: 335px;
      margin: 0 auto;
    }
  }

  &__clm3 {
    background: #fff;
    padding: 20px 20px 10px;
    display: flex;
    justify-content: center;

    &__li {
      max-width: 105px;

      &:nth-child(2) {
        margin: 0 10px;
      }
    }
  }

  &__list {
    &__li+&__li {
      border-top: 1px solid #EFEFEF;
    }

    &__li {
      font-size: 18px;
    }
  }

  &__clm2 {
    font-size: 14px;
    background: $color-blue;
    color: #fff;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 40px;

    &__li {
      width: 50%;
      border-bottom: 1px solid #fff;

      &:nth-child(even) {
        border-left: 1px solid #fff;
      }

      a {
        font-weight: $bold;
        color: #fff;
        display: block;
        text-align: center;
        font-size: 14px;
        text-decoration: none;
        padding: 12px 0;
      }
    }

    &__full {
      width: 100%;
      display: flex;
      justify-content: center;

      .p-header__lang {
        padding: 20px 0;
      }
    }
  }
}

@media only screen and (min-width: $mq-pc) {}

@media only screen and (max-width: $mq-sp) {}