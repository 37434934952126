//topics {
.p-topics-label {
  min-width: 9em;
  text-align: center;
  display: inline-block;
  line-height: 1;
  padding: 5px;
  color: #fff;
  background: #000;

  @media only screen and (min-width: $mq-pc) {
    font-size: 14px;
  }

  @media only screen and (max-width: $mq-sp) {
    font-size: 12px;
  }

  *+& {
    margin-left: 10px;
  }

  &--important {
    background: #AC4352;
  }

  &--latest {
    background: #496F42;
  }

  &--news {
    background: #C8AB21;
  }

  &--campaign {
    background: #C3722A;
  }

  &--store {
    background: #4CA2A2;
  }

  &--press {
    background: #5C3E31;
  }
}

.p-topics-block {

  *+& {
    @media only screen and (min-width: $mq-pc) {
      margin-top: 60px;
    }

    @media only screen and (max-width: $mq-sp) {
      margin-top: 20px;
    }
  }
}

.p-topics-list {
  border-bottom: 1px solid rgba(10, 45, 136, .2);

  @media only screen and (min-width: $mq-pc) {
    margin-top: 30px;
    padding-bottom: 10px;
  }

  @media only screen and (max-width: $mq-sp) {
    margin-top: 20px;
    padding-bottom: 10px;
  }

  &__date {
    font-weight: bold;
    display: flex;
    align-items: center;
    width: 100%;

    @media only screen and (min-width: $mq-pc) {
      font-size: 18px;
    }

    @media only screen and (max-width: $mq-sp) {
      font-size: 16px;
    }
  }

  &__text {
    margin-top: 15px;
    font-size: 16px;
  }

  &__link {
    display: block;
    position: relative;
  }
}

//topic detail
.p-topics-date {
  margin-bottom: 20px;
}

.p-topics-sns {
  width: 100%;
  display: flex;

  @media only screen and (min-width: $mq-pc) {
    margin: 30px auto;
  }

  @media only screen and (max-width: $mq-sp) {
    margin: 20px auto;
  }

  &__li+&__li {
    margin-left: 15px;
  }
}

//inquiry
.p-inquirybox {
  text-align: center;
  color: $color-blue;

  &__text {
    line-height: 1.5;

    @media only screen and (min-width: $mq-pc) {
      font-size: 16px;
    }

    @media only screen and (max-width: $mq-sp) {
      font-size: 14px;
    }

    >span {
      display: inline-block;
    }
  }

  &__clm {
    margin-top: 15px;

    @media only screen and (min-width: $mq-pc) {
      display: flex;
      justify-content: center;
    }

    @media only screen and (max-width: $mq-sp) {}

    &__li {
      display: flex;
      align-items: flex-start;

      @media only screen and (min-width: $mq-pc) {
        margin: 0 10px;
      }

      @media only screen and (max-width: $mq-sp) {
        justify-content: center;
        margin-top: 15px;
      }

    }
  }

  &__label {
    font-size: 20px;
    line-height: 1;
    background: $color-blue;
    color: #fff;
    padding: 5px;
    margin-right: 10px;
  }

  &__num {
    font-size: 30px;
    line-height: 1;

    a {
      display: block;
      color: $color-blue;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    small {
      margin-top: 8px;
      font-size: 12px;
      display: block;
      text-align: center;
    }
  }

  &__small {
    margin-top: 15px;
    font-size: 12px;
    text-align: center;
  }
}

//contact
.p-contactbox {
  line-height: 1.5;

  &__icon {
    text-align: center;
  }

  &__title {
    text-align: center;
    font-size: 14px;
    font-weight: $bold;
    color: $color-blue;
    margin-top: 12px;
  }

  &__text {
    margin-top: 1em;
    line-height: (30 /16);

    @media only screen and (min-width: $mq-pc) {
      font-size: 16px;
    }

    @media only screen and (max-width: $mq-sp) {
      font-size: 14px;
    }
  }

  &__subtitle {
    text-align: center;
    font-size: 14px;
    font-weight: $bold;
    color: $color-blue;
    margin-top: 20px;
  }

  &__subtitle+&__num {
    margin-top: 6px;
  }

  &__num {
    margin-top: 12px;
    text-align: center;

    a {
      color: $color-blue;
      font-size: 30px;
      font-weight: $bold;
    }
  }

  &__small {
    margin-top: 1em;
    text-align: center;
    color: $color-blue;
    font-size: 12px;
  }

  &__btn {
    margin-top: 18px;
  }
}

.p-accordion {
  &__trigger {
    position: relative;
    display: block;
    width: 100%;
    transition: all 0.2s ease-in;
    outline: none;
    border: none;
    cursor: pointer;

    &:before,
    &:after {
      position: absolute;
      top: 0;
      bottom: 0;
      left: auto;
      right: 20px;
      margin: auto;
      content: '';
      display: inline-block;
      width: 18px;
      height: 0px;
      border-top: 2px solid #fff;
    }

    &:after {
      transform: rotate(90deg);
    }

    &.is-acopen {
      &:after {
        opacity: 0;
      }
    }
  }

  &__target {
    overflow: hidden;
    transition: height 0.4s ease-in-out;
  }

  &__title {
    line-height: 1.5;
    background: $color-blue;
    color: #fff;
    font-size: 16px;
    font-weight: $bold;
    padding: 10px 20px 10px 20px;
  }

  &__cont {
    background: #fff;
  }
}

.js-accordion {
  &__trigger {}

  &__target {}

  &-enter-active {}

  &-leave-active {}
}

@keyframes _opend {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes _closed {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@media only screen and (min-width: $mq-pc) {}

@media only screen and (max-width: $mq-sp) {}