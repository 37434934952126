.l-pagetop {}

.p-pagetop {

  &__btn {
    position: relative;
    margin: 0 auto;

    @media only screen and (min-width: $mq-pc) {
      max-width: 1024px;
      width: 1024px;
    }

    @media only screen and (max-width: $mq-sp) {}
  }

  &__link {
    display: block;
    position: absolute;
    z-index: 1;
    bottom: 10px;
    right: 19px;
  }
}

//breadcrumb
.l-breadcrumb {
  background: #fff;
  position: relative;
  z-index: 1;
}

.p-breadcrumb {
  width: 100%;
  font-size: 12px;
  display: flex;
  flex-wrap: wrap;
  padding-top: 5px;
  padding-bottom: 15px;

  &__li {
    margin-top: 10px;
    position: relative;
  }

  &__li+&__li {
    margin-left: 2em;

    &:before {
      content: '';
      display: block;
      position: absolute;
      z-index: 1;
      left: -1.1em;
      top: -2px;
      bottom: 0;
      margin: auto;
      width: 4px;
      height: 4px;
      border-top: 1px solid #000;
      border-right: 1px solid #000;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

//footer
.l-footer {
  position: relative;
  z-index: 1;
}

.p-footer {
  line-height: 1.5;

  &.is-appbnr {
    @media only screen and (min-width: $mq-pc) {
      margin-top: 160px;
    }

    @media only screen and (max-width: $mq-sp) {
      margin-top: 102px;
    }
  }

  &__inner {
    padding: 0 20px;
    margin: 0 auto;
    position: relative;

    @media only screen and (min-width: $mq-pc) {
      max-width: 1024px;
      width: 1024px;
    }
  }

  &__navupper,
  &__navlower {
    background: $color-blue;
    color: #fff;

    .p-footer__inner {

      @media only screen and (min-width: $mq-pc) {
        display: flex;
        justify-content: space-between;
      }

      @media only screen and (max-width: $mq-sp) {}
    }

    a {
      color: #fff;
      font-weight: $bold;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__navupper {

    @media only screen and (min-width: $mq-pc) {
      padding: 40px 0;
    }

    @media only screen and (max-width: $mq-sp) {
      padding: 15px 0 30px;
    }
  }

  &__navlower {
    border-top: 1px solid #fff;
    padding: 20px 0;

    &list {
      width: 100%;
      display: flex;

      @media only screen and (min-width: $mq-pc) {
        justify-content: center;
        align-items: center;
      }

      @media only screen and (max-width: $mq-sp) {
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        margin-top: -10px;
      }

      &__li {
        @media only screen and (min-width: $mq-pc) {
          padding: 0 20px;
        }

        @media only screen and (max-width: $mq-sp) {
          margin-top: 10px;
          padding: 0 10px;
        }
      }

      &__li+&__li {
        border-left: 1px solid #fff;
      }
    }
  }

  &__l {
    @media only screen and (min-width: $mq-pc) {
      width: 15em;
    }

    @media only screen and (max-width: $mq-sp) {
      width: 100%;
    }
  }

  &__c {
    font-weight: $bold;

    @media only screen and (min-width: $mq-pc) {
      flex: 1 0 0%;
    }

    @media only screen and (max-width: $mq-sp) {
      margin-top: 18px;
    }
  }

  &__r {
    @media only screen and (min-width: $mq-pc) {
      margin-left: 40px;
      width: 363px;
    }

    @media only screen and (max-width: $mq-sp) {
      margin-top: 18px;
    }

    &__bnr {
      margin-top: -20px;
    }
  }

  &__sns {
    margin-top: 20px;
    border: 2px solid #fff;

    &__title {
      background: #fff;
      color: $color-blue;
      font-weight: $bold;
      text-align: center;
      padding: 5px 0;

      @media only screen and (min-width: $mq-pc) {
        font-size: 16px;
      }

      @media only screen and (max-width: $mq-sp) {
        font-size: 16px;
      }
    }

    &__list {
      display: flex;
      justify-content: center;
      padding: 18px 0;

      &__li {
        margin: 0 10px;
      }
    }
  }

  &__list {

    @media only screen and (max-width: $mq-sp) {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      &__li {
        margin-top: 1.1em;
        width: 50%;
      }
    }

    &--2clm {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    &__li {
      .p-footer__list--2clm & {
        margin-top: 1.1em;
        width: 50%;
        padding-left: 1em;

        @media only screen and (max-width: $mq-sp) {
          font-size: 12px;
          padding-left: 1.1em;
        }

        &:nth-child(even) {
          @media only screen and (max-width: $mq-sp) {
            padding-left: 0;
          }
        }
      }
    }

    &__li+&__li {
      margin-top: 1.1em;
    }
  }

  &__store {
    background: #fff;

    &list {
      display: flex;
      flex-wrap: wrap;

      @media only screen and (min-width: $mq-pc) {
        padding: 20px 6px;
      }

      @media only screen and (max-width: $mq-sp) {
        padding: 8px 0;
      }

      li {
        @media only screen and (min-width: $mq-pc) {}

        @media only screen and (max-width: $mq-sp) {
          width: calc(100% / 6);
        }

        img {
          width: 100%;
          height: auto;
        }

        >span,
        >a {
          display: block;
          @media only screen and (min-width: $mq-pc) {
            width: 57px;
          }


          span,
          a {
            display: block;
            padding: 4px;
          }
        }
      }
    }
  }

  &__cr {
    background: #fff;
    border-top: 1px solid #E0E5E9;
    padding: 30px 0;
    text-align: center;

    &__logo {}

    &__text {
      font-size: 10px;
      margin-top: 20px;
    }
  }
}

.p-footer-accordion {
  @media only screen and (max-width: $mq-sp) {
    &__header {
      background: #fff;
      display: flex;
      align-items: center;
      padding: 10px 19px;

      &__l {
        flex: 1 0 0%;
        display: flex;
        align-items: center;
        white-space: nowrap;
      }
    }

    &__logo {
      display: flex;
      align-items: center;

      a {
        display: block;
        padding: 4px;
      }

      >div {
        width: 33.3333%;
        max-width: 57px;
      }

      img {
        width: 49px;
        height: auto;

        @media only screen and (max-width: 374px) {
          width: 100%;
        }
      }
    }

    &__text {
      display: flex;
      align-items: center;
      margin-left: auto;
      margin-right: 10px;
      color: $color-blue;
      font-weight: $bold;
    }

    &__trigger {
      width: 40px;
      height: 40px;
      background: #F5F5F5;
      position: relative;
      transition: all 0.2s ease-in;
      display: block;
      outline: none;
      border: none;
      cursor: pointer;

      &:before,
      &:after {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        content: '';
        display: inline-block;
        width: 18px;
        height: 0px;
        border-top: 2px solid #B1BCC4;
      }

      &:after {
        transform: rotate(90deg);
      }

      &.is-acopen {
        &:after {
          opacity: 0;
        }
      }
    }

    &__target {
      border-top: 1px solid #e0e5e9;
      overflow: hidden;
      transition: height 0.4s ease-in-out;
    }
  }

  @media only screen and (min-width: $mq-pc) {
    &__target {
      height: auto !important;
    }
  }
}

@media only screen and (min-width: $mq-pc) {}

@media only screen and (max-width: $mq-sp) {}