body {
  font-family: 'Roboto', 'Noto Sans JP', sans-serif;
  font-size: 14px;
  font-weight: $normal;

  @media only screen and (min-width: $mq-pc) {
    min-width: 1024px;
  }
}

* {
  box-sizing: border-box;
}

a {
  color: $color-blue;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

[v-cloak] {
  display: none;
}

img[lazy=loading] {
  position: absolute;
  z-index: 1;
  width: 32px;
  height: 32px;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  max-width: none;
}

@media only screen and (min-width: $mq-pc) {}

@media only screen and (max-width: $mq-sp) {}